body {
  margin: 0;
}

.viewer3D canvas {
  display: block;
  outline: none;
  margin: 0;
  padding: 0;
}

#gui {
  position: absolute;
  top: 60px;
  left: 20px;
  z-index: 10000;
  display: none;
}

body.viewer #gui {
  display: block;
}

@media print {
  body a.iubenda-tp-btn.iubenda-cs-preferences-link,
  body a.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-right],
  body a.iubenda-tp-btn[data-tp-float][data-tp-float=top-right],
  body a.iubenda-tp-btn[data-tp-float][data-tp-float=top-left],
  body a.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-left] {
    display: none !important;
  }
}

body a.iubenda-tp-btn.iubenda-cs-preferences-link,
body a.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-right],
body a.iubenda-tp-btn[data-tp-float][data-tp-float=top-right],
body a.iubenda-tp-btn[data-tp-float][data-tp-float=top-left],
body a.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-left] {
  right: 0px !important;
  left: auto !important;
  top: 0px !important;
  bottom: auto !important;
  float: right !important;
  height: 44px  !important;
  width: 44px !important;
}

@media all and (max-width: 899px) {
  body a.iubenda-tp-btn.iubenda-cs-preferences-link,
  body a.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-right],
  body a.iubenda-tp-btn[data-tp-float][data-tp-float=top-right],
  body a.iubenda-tp-btn[data-tp-float][data-tp-float=top-left],
  body a.iubenda-tp-btn[data-tp-float][data-tp-float=bottom-left] {
    top: 16px !important;
    right: 16px !important;
    height: 34px  !important;
    width: 34px !important;
  }
}
